import React from "react";
import {FormBuilder} from "@jaguardesignstudio/silentgrowl-form-builder-react";
// import RegistrationPageSignifiers from "./RegistrationPageSignifiers";
import RegistrationCheckBoxField from "./RegistrationCheckBoxField";
import RegistrationHtmlField from "./RegistrationHtmlField";

class RegistrationAMFormWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  callback(json) {
    console.log('am reg submit complete');
    console.log(json);
  }

  getReplacableStrings() {
    const { participant, customer } = this.props;
    return [
      {key: '*|EMAIL|*', value: participant.email },
      {key: '*|CUSTOMERFNAME|*', value: customer.name_first},
      {key: '*|CUSTOMERLNAME|*', value: customer.name_last},
      {key: '*|CUSTOMERCOMPANY|*', value: customer.company},
      {key: '*|CUSTOMEREMAIL|*', value: customer.email},
      {key: '*|TICKETNAME|*', value: this.getTicketName(customer)}
    ]
  }

  getTicketName(participant) {
    const { ticket_info } = this.props;
    let name = '';
    let ticket_gid = this.getMetadataValue('programname', participant);
    ticket_info.tickets.forEach(ticket => {
      if (ticket.gid === ticket_gid) {
        name = ticket.name;
      }
    });
    return name;
  }

  getMetadataValue(slug, participant) {
    let value = '';
    participant.metadata.forEach(option => {
      if (option.field_slug === slug) {
        value = option.value;
      }
    });
    return value;
  }

  render() {
    const { package_info, ticket_info, participant, form } = this.props;
    console.log(participant);
    console.log(ticket_info);
    return (
      <FormBuilder
        key="registration-form"
        components={{
          // pageSignifiers: RegistrationPageSignifiers
          // checkboxfield: RegistrationCheckBoxField
          html: RegistrationHtmlField
        }}
        // ticket_info={package_info}
        ticket_info={ticket_info}
        participant={participant}
        form={form}
        gid={form.gid}
        replacableStrings={
          this.getReplacableStrings()
        }
        submitUrl={'/ams'}
        submitCallback={this.callback}
        type={'registration'}
        use_legacy_ticketing={false}
        submit_spinner={true}
      />
    );
  }
}

export default RegistrationAMFormWrapper;
