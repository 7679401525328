import React from "react";
import CmsPrint from "@utility/cms/CmsPrint";

class LandingHighlightBoxItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true
    }
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  toggleIsOpen() {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  renderImg() {
    const { box } = this.props;
    if (box.img) {
      return (
        <div className="landing-page-card-image">
          <img src={box.img} />
        </div>
      )
    }
    if (box.ico) {
      return (
        <div className="landing-page-card-ico">
          <img src={box.ico} />
        </div>
      );
    }
    return <></>;
  }

  renderLabel() {
    const { box, cms_section, slug } = this.props;
    if (box.label) {
      return (
        <div className="landing-page-card-label body2">
          <CmsPrint cms_section={cms_section} slug={slug} field={box.label} />
        </div>
      )
    }
    return <></>;
  }

  render() {
    const { box, cms_section, slug } = this.props;
    const { isOpen } = this.state;
    return (
      <div className="landing-page-card-wrapper">
        <div className="landing-page-card">
          {this.renderImg()}
          <div className="landing-page-card-content">
            {this.renderLabel()}
            <h5 style={{marginBottom: "16px"}}>
              <CmsPrint cms_section={cms_section} slug={slug} field={box.title} />
            </h5>
            {
              isOpen ?
              <div className="body1">
                <CmsPrint cms_section={cms_section} slug={slug} field={box.body} />
              </div>
              :
              <></>
            }
            <div className="landing-page-card-chevron">
              {
                isOpen ?
                <img src="/images/landing/ico-chevron-up.svg" onClick={this.toggleIsOpen}/>
                :
                <img src="/images/landing/ico-chevron-down.svg" onClick={this.toggleIsOpen}/>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LandingHighlightBoxItem;
