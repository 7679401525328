import React, { Component } from 'react'

export default class RegistrationTicketField extends Component {

  translateCSS(css) {
    // TODO: Implement css -> jsx styling
    let jsonCSS = {}
    if (css !== '') {
      try {
        jsonCSS = JSON.parse(css)
      } catch (e) {
        return {}
      }
    }
    return jsonCSS;
  }

  getFormikValue(attribute_id, formikProps) {
    const splitQuestionId = attribute_id.split('.');
    let formikPropsParticipant = formikProps.values["participant"];
    return formikPropsParticipant["metadata"][splitQuestionId[2]];
  }

  setFormikValue(value, attribute_id, formikProps) {
    const splitQuestionId = attribute_id.split('.');
    let formikPropsParticipant = formikProps.values["participant"];
    formikPropsParticipant["metadata"][splitQuestionId[2]] = value;
    formikProps.setFieldValue('participant', formikPropsParticipant);
  }

  renderQuestionLabel(question) {
    return (
      <div className="label-wrapper">
        <label style={{fontSize: "14px", marginBottom: "16px"}} className={`label-english ${question.required ? 'required':''}`}> {question.label} </label>
      </div>
    );
  }

  renderTicketName(ticket) {
    switch(ticket.name) {
      case 'Thursday':
        return (
          <>
            <b>Thursday</b>
            <br />
            January 30
            <br />
            First Round
          </>
        );
      case 'Friday':
        return (
          <>
            <b>Friday</b>
            <br />
            January 31
            <br />
            Second Round
          </>
        );
      case 'Saturday':
        return (
          <>
            <b>Saturday</b>
            <br />
            February 1
            <br />
            Third Round
          </>
        );
      case 'Sunday':
        return (
          <>
            <b>Sunday</b>
            <br />
            February 2
            <br />
            Final Round
          </>
        );
      default:
        console.log(`Sorry, we are out of ${expr}.`);
    }
  }

  render() {
    const { type, formikProps, question, checkError, ticket_info } = this.props;

    let value = this.getFormikValue(question.attribute_id, formikProps, type);
    const rendereredDates = ticket_info['tickets'].sort((a,b)=>a.id - b.id).map(ticket=> (
      <div onClick={()=> {
        if(ticket.available) {
          // const { participant } = formikProps.values;
          this.setFormikValue(ticket.gid, question.attribute_id, formikProps, type);
        }
      }} disabled={!ticket.available} className={`sg-masters-date-select-item ${(value == ticket.gid ? "active" : "")} ${!ticket.available ? "disabled" : ""} `}>
        <div className="sg-masters-date-select-item-inner">
          <img src={`/registration/days/${ticket.gid}${(value == ticket.gid ? "-active" : "")}.svg`} className="sg-masters-date-select-item-image"/>
          <div className="sg-masters-date-select-item-copy body1" style={{textAlign: 'center'}}>
            {this.renderTicketName(ticket)}
          </div>
          <div className="checkmark-image-wrapper">
            <img src={`/registration/days/checkmark.png`} className="sg-masters-date-select-checkmark-image"/>
          </div>
          <div className="sg-masters-date-select-item-disabled-message">
            No longer available
          </div>
        </div>
      </div>
    ))
    return (
      <>
        <div className={`col-xs-12`}>
          <div className="sg-masters-date-select-item-container">
            {rendereredDates}
          </div>
          <div className="registration-field-error">
            {checkError && ticket_info['tickets'].map(ticket => {return (ticket.gid)} ).indexOf(value) == -1 ? "Please select an option to continue." : ""}
          </div>
        </div>
      </>
    )

  }
}
