import React from "react";
import LandingHighlightBoxItem from "./LandingHighlightBoxItem";

class LandingHighlights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  getColumnClass(columnNum) {
    let className = "col-md-12";
    switch (columnNum) {
      case 1:
        className = "col-md-12";
        break;
      case 2:
        className = "col-md-6";
        break;
      case 3:
        className = "col-md-4";
        break;
      default:
        className = "col-md-12";
    }
    return className;
  }

  render() {
    const { boxes, cms_section, slug } = this.props;
    return (
      <div className="container" style={{marginTop: "24px"}}>
        <div className="row equal landing-page-cards-wrapper" style={{marginLeft: "-12px", marginRight: "-12px"}}>
        {
          boxes.map((box, index) =>
            <div className={`col-xs-12 ${this.getColumnClass(boxes.length)}`} style={{padding: "0px 12px"}} key={`hightlight-box-${index}`}>
              <LandingHighlightBoxItem box={box} cms_section={cms_section} slug={slug} />
            </div>
          )
        }
        </div>
      </div>
    );
  }
}

export default LandingHighlights;
