import React, { useState, useEffect } from "react";
const LandingPageHeaderNav = ({header_sections, include_images}) => {
  const [selectedItem, setSelectedItem] = useState(header_sections[0].id);

  useEffect(() => {
    const onScroll = e => {
      let stillLooking = true;
      let currentActive = selectedItem;
      if ((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight) {
        setSelectedItem(header_sections[header_sections.length - 1].id);
      } else {
        for (let index = header_sections.length - 1; index >= 0; index--) {
          let element = document.getElementById(header_sections[index].id);
          if (element && element.getBoundingClientRect().top < 1) {
            if (stillLooking) {
              if (currentActive !== header_sections[index].id) {
                setSelectedItem(header_sections[index].id);
              }
            }
            stillLooking = false;
          }
        }
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [selectedItem]);

  const renderHeaderItems = () => {
    const renderedHeaderItems = header_sections.map(item =>
      <div className={`landing-page-nav-item ${item.id === selectedItem ? 'active' : ''}`} key={`header-item-${item.id}`}>
        <a href={`#${item.id}`}>
          <div className="landing-page-nav-item-flex">
            {
              include_images ?
              <img src={`/images/landing/ico-${item.id}.svg`} />
              :
              <></>
            }
            <div className="body1">{item.title}</div>
          </div>
        </a>
      </div>
    );
    return renderedHeaderItems;
  };

  return (
    <div className="hidden-sm hidden-xs">
      <div className="landing-page-nav">
        {renderHeaderItems()}
      </div>
    </div>
  );
};

export default LandingPageHeaderNav;
